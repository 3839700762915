<template>
  <div>
    <div class="wrapper">
      <el-carousel
        id="el-carousel1"
        :interval="5000"
        arrow="never"
        ref="slideCarousel1"
        style="height: 655px;"
      >
        <el-carousel-item v-for="(item, index) in bannerImgs1" :key="index">
          <img :src="item.url" class="banner-img" />
          <!-- <span v-for="(tItem, tIndex) in bannerFloatText" :key="tIndex">
            <i class="twins-park-float-text" v-show="index == tIndex">{{
              tItem.name
            }}</i>
          </span> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-main">
      <div class="home-a wr">
        <div
          class="tit-60 text-center lh1 hnb"
          style="visibility: visible"
        >
          重点推荐
        </div>
        <div class="main wr">
          <div class="bg list-bg1" @click="goToPage('/acquisitionSystem')"></div>
            <div class="txt tit-16 t2">
              物联网IoT设备管理与数据采集系统
            </div>
        </div>
        <div class="list1 f-cb">
          <a
            @click="goToPage('/waterSystem')"
            target="_blank"
          >
            <div class="bg list-bg2"></div>
            <div class="txt tit-30 text-center col-fff">
              城乡一体化数字孪生水务系统
            </div>
          </a>
          <a
            @click="goToPage('/controlSystem')"
          >
            <div class="bg list-bg3"></div>
            <div class="txt tit-30 text-center col-fff">
              无人值守泵站控制系统
            </div>
          </a>
          <a
            @click="goToPage('/operatingSystem')"
          >
            <div class="bg list-bg4"></div>
            <div class="txt tit-30 text-center col-fff">
              人员巡点检智能运维系统
            </div>
          </a>
        </div>
        <div class="main wr" @click="goToPage('/reportingSystem')">
          <img
            style="width: 100%;margin-top: 20px;"
            src="../../img/index-bg5.png"
            alt="5T技术"
          />
          <div class="txt tit-30 text-center col-fff">
            全域数据分析与报表系统
          </div>
        </div>
      </div>
      <div class="home-b wr">
        <div
          class="tit-60 hnb lh1"
          style="visibility: visible"
        >
          联系方式
        </div>
        <div class="list wr f-cb">
          <div
            class="li"
          >
            <div class="icon">
              <img
                :src="require('@/img/address.png')"
                alt=""
                class="tran-middle"
              />
            </div>
            <div class="tit-24 mc hnb">地址</div>
            <div class="tit-16" style="padding: 0 22px;">西安市高新区高新三路8号橙市区空间5楼0520室</div>
          </div>
          <div
            class="li"
          >
            <div class="icon">
              <img
                :src="require('@/img/phone.png')"
                alt=""
                class="tran-middle"
              />
            </div>
            <div class="tit-24 mc hnb">电话</div>
            <div class="tit-16" style="padding: 0 22px;">400-029-2281（总机转）</div>
          </div>

          <div
            class="li"
          >
            <div class="icon">
              <img
                :src="require('@/img/email.png')"
                alt=""
                class="tran-middle"
              />
            </div>
            <div class="tit-24 mc hnb">E-mail</div>
            <div class="tit-16" style="padding: 0 22px;">INFO@DBIT365.com</div>
          </div>

          <div
            class="li"
            data-wow-delay=".5s"
          >
            <div class="icon">
              <img
                :src="require('@/img/youbian.png')"
                alt=""
                class="tran-middle"
              />
            </div>
            <div class="tit-24 mc hnb">邮编</div>
            <div class="tit-16" style="padding: 0 22px;">710075</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerImgs1: [
        {
          name: "智慧园区",
          url: require("@/img/swiper1.jpg"),
        },
        {
          name: "智慧水厂",
          url: require("@/img/swiper2.jpg"),
        },
        {
          name: "智慧城市",
          url: require("@/img/swiper3.jpg"),
        },
      ],
      bannerFloatText: [
        { name: "智慧园区" },
        { name: "智慧水厂" },
        { name: "智慧城市" },
      ],
      bannerImgs2: [
        {
          name: "智慧园区",
          url: require("@/img/twins-park.jpg"),
        },
        {
          name: "智慧水厂",
          url: require("@/img/twins-park2.jpg"),
        },
        {
          name: "智慧城市",
          url: require("@/img/twins-city.jpg"),
        },
      ],
      bannerImgs3: [
        {
          name: "运维系统",
          url: require("@/img/operation-home.jpg"),
        },
        {
          name: "数字孪生",
          url: require("@/img/twins-city.jpg"),
        },
        {
          name: "组态模块",
          url: require("@/img/configuration.jpg"),
        },
      ],
      bannerTabText: [
        { name: "运维系统" },
        { name: "数字孪生" },
        { name: "组态模块" },
      ],
      bannerHeight1: 701,
      screenWidth1: 1920,

      bannerHeight2: 600,
      screenWidth2: 1920,

      bannerHeight3: 500,
      screenWidth3: 1920,
      currentIndex: "a" + 0,
    };
  },
  methods: {
    //跳转
    goToPage(menue) {
      this.$router.push(menue);
    },
    setSize1: function () {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.screenWidth1 = width;
      this.screenWidth2 = width;
      this.screenWidth3 = width;
      //图片                高 / 宽  500 / 1920
      this.bannerHeight1 = 990 / 1920 * this.screenWidth1 - 50
      this.bannerHeight2 = 600 / 1920 * this.screenWidth2 - 50
      this.bannerHeight3 = 500 / 1920 * this.screenWidth3 - 50
      document.getElementById("el-carousel1").style.height =
        this.bannerHeight1 + "px";
      document.getElementById("el-carousel2").style.height =
        this.bannerHeight2 + "px";
      document.getElementById("el-carousel3").style.height =
        this.bannerHeight3 + "px";
    },
    setSize: function () {
      this.bannerHeight1 = (990 / 1920) * this.screenWidth1 - 50;
      this.bannerHeight2 = (600 / 1920) * this.screenWidth2 - 50;
      this.bannerHeight3 = (500 / 1920) * this.screenWidth3 - 50;
      document.getElementById("el-carousel1").style.height =
        this.bannerHeight1 + "px";
      document.getElementById("el-carousel2").style.height =
        this.bannerHeight2 + "px";
      document.getElementById("el-carousel3").style.height =
        this.bannerHeight3 + "px";
    },
  },
  mounted() {
    // this.setSize1();
    // const that = this;
    // //监听浏览器窗口大小改变
    // window.addEventListener(
    //   "resize",
    //   function () {
    //     var width =
    //       window.innerWidth ||
    //       document.documentElement.clientWidth ||
    //       document.body.clientWidth;
    //     that.screenWidth1 = width;
    //     that.screenWidth2 = width;
    //     that.screenWidth3 = width;
    //     that.setSize();
    //   },
    //   false
    // );
  },
};
</script>
<style lang="less" scoped>
// web端样式
.wrapper {
  position: relative;
}
.list-bg1 {
  background-image: url("../../img/index-bg1.jpg");
}
.list-bg2 {
  background-image: url("../../img/index-bg2.png");
}
.list-bg3 {
  background-image: url("../../img/index-bg3.jpg");
}
.list-bg4 {
  background-image: url("../../img/index-bg4.png");
}
.home-main {
  width: 100%;
  padding: 0 12rem;
  background-image: url("../../img/bg.jpg");
  .home-a {
    padding-top: 5rem;
    .main {
      height: 37.5rem;
      margin-top: 5.1rem;
      position: relative;
      cursor: pointer;
      .txt {
        position: absolute;
        bottom: 30%;
        left: 0;
        width: 100%;
        line-height: 1;
        font-size: 56px;
        font-weight: bold;
        padding: 0 30px 40px;
        color: #fff;
      }
    }
    .list1 {
        width: 100%;
        margin-top: 20px;
        .txt {
          position: absolute;
          bottom: 30%;
          left: 0;
          width: 100%;
          line-height: 1;
          font-size: 56px;
          font-weight: bold;
          padding: 0 30px 40px;
        }
        a {
          display: block;
          width: 32.38%;
          height: 600px;
          overflow: hidden;
          float: left;
          margin-right: 20px;
          position: relative;
          cursor: pointer;
        }
        a:last-child {
            margin-right: 0;
        }
    }
  }
  .home-b {
    height: 737px;
    padding: 168px 0 175px;
    .list {
      margin-top: 134px;
      .li {
            width: 23.35%;
            margin-right: 1.88rem;
            height: 200px;
            background: rgba(255, 255, 255, 0.14);
            border: 1px solid rgba(0, 65, 195, .14);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            float: left;
            position: relative;
            .icon {
                position: absolute;
                top: 0;
                left: 50%;
                width: 70px;
                height: 70px;
                background: #0041C3;
                border: 1px solid #FFFFFF;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            
                .tran-middle {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                img {
                    display: block;
                    max-width: 100%;
                }
            }
        }
        .li:last-child {
            margin-right: 0;
        }
    }
  }
}

.banner-img {
  width: 100%;
}
.twins-park-float-text {
  display: block;
  position: absolute;
  bottom: 15%;
  right: 15%;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 10px;
}
.leftArrow {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 50%;
  left: 3%;
  z-index: 990;
  background: #0048c0;
  border-radius: 50%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.leftArrow:hover {
  opacity: 0.7;
}
.rightArrow {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 50%;
  right: 3%;
  z-index: 990;
  background: #0048c0;
  border-radius: 50%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.rightArrow:hover {
  opacity: 0.7;
}
.home-bg1 {
  width: 100%;
  height: 100vh;
  background-image: url("../../img/bg1.png");
  background-size: 100% 100%;
  position: relative;
}
.home-bg1-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 0 50px;
}
.tip-text {
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  .tip-text-title {
    font-size: 20px;
    height: 0px;
  }
  .tip-text-con {
    font-size: 40px;
    height: 30px;
  }
  .tip-text-btn {
    width: 160px;
    height: 50px;
    border: 2px solid #ffffff;
    border-radius: 15px;
    text-align: center;
    line-height: 50px;
    font-size: 22px;
    transition: all 0.3s ease;
  }
  .tip-text-btn:hover {
    background: #ffffff;
    color: #0048c0;
    cursor: pointer;
  }
}
.right-banner {
  width: 1200px;
  margin-left: 10%;
  position: relative;
  background: #020628;
  .right-banner-before1 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 1;
    border: 2px solid #ffffff;
    background: #020628;
  }
  .right-banner-before2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -20px;
    left: -20px;
    z-index: 0;
    border: 2px solid #ffffff;
    background: #020628;
  }
  .leftArrow {
    top: 45%;
  }
  .rightArrow {
    top: 45%;
  }
}

.home-bg2 {
  width: 100%;
  height: 100vh;
  background-image: url("../../img/bg2.png");
  background-size: 100% 100%;
  position: relative;
}
.home-bg2-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.middle-banner {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
  .leftArrow {
    top: 45%;
  }
  .rightArrow {
    top: 45%;
  }
}
.middle-banner-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .m-b-txt {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      color: #ffffff;
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 5px;
      cursor: pointer;
      border-bottom: 4px solid transparent;
    }
    .acitve-text {
      border-bottom: 4px solid #ffffff;
    }
    span:hover {
      color: #cccccc;
    }
  }
}
.home-footer {
  width: 100%;
  height: 290px;
  position: relative;
  background: #000000;
  box-sizing: border-box;
  .footer-con {
    height: 70px;
    border-bottom: 3px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 100px;
    .con-logo {
      height: 180px;
    }
    .con-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #ffffff;
        font-size: 24px;
        width: 20%;
        display: block;
        text-align: center;
        cursor: pointer;
      }
      span:hover {
        color: #cccccc;
      }
    }
    .con-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-wrapper {
        position: relative;
        .icon-phone-con {
          width: 150px;
          position: absolute;
          top: -80px;
          right: 0px;
          background: #ffffff;
          border-radius: 10px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          display: none;
          .phone-title {
            font-size: 14px;
            color: #888888;
            padding-bottom: 5px;
            display: inline-block;
          }
          .phone-con {
            font-size: 24px;
            color: #000000;
            display: inline-block;
          }
          .icon-phone-arrow {
            position: absolute;
            bottom: -35px;
            right: 30px;
            border-left: 10px solid transparent;
            border-top: 10px solid #ffffff;
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
        }
      }
      .icon-wrapper:hover .icon-phone-con {
        display: block;
      }
      img {
        height: 60px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}
.carousel2 {
  border: 2px solid #ffffff;
  z-index: 2;
}
.carousel3 {
  .carousel3-item {
    height: 98%;
    border: 2px solid #ffffff;
  }
}

// 移动端样式
@media screen and (max-width: 650px) {
  .wrapper {
    margin-top: 0;
  }
  .twins-park-float-text {
    font-size: 14px;
    letter-spacing: 3px;
  }
  .home-bg1 {
    height: 450px;
  }
  .home-bg1-content {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .right-banner {
    width: 100%;
    margin: 0;
    background: none;
    .leftArrow {
      top: 40%;
    }
    .rightArrow {
      top: 40%;
    }
  }
  .tip-text {
    margin-bottom: 30px;
  }
  .tip-text-title {
    font-size: 14px !important;
    height: 0px;
  }
  .tip-text-con {
    font-size: 24px !important;
    height: 30px;
  }
  .tip-text-btn {
    width: 120px !important;
    height: 30px !important;
    border: 2px solid #ffffff;
    border-radius: 15px;
    text-align: center;
    line-height: 30px !important;
    font-size: 14px !important;
    transition: all 0.3s ease;
  }
  .home-bg2 {
    height: 200px;
  }
  .m-b-txt {
    span {
      font-size: 14px !important;
    }
  }
  .home-footer {
    height: 200px !important;
  }
  .footer-con {
    flex-direction: column;
    border-bottom: 0 !important;
    margin: 0 !important;
  }
  .con-logo {
    height: 100px !important;
  }
  .con-list {
    span {
      width: 100% !important;
      display: inline-block;
      font-size: 14px !important;
    }
  }
  .con-icon {
    margin-top: 20px;
    img {
      height: 40px !important;
      margin: 0 5px !important;
    }
  }
  .middle-banner {
    margin-bottom: 20px;
    .leftArrow {
      top: 30%;
    }
    .rightArrow {
      top: 30%;
    }
  }
  .leftArrow {
    top: 40%;
  }
  .rightArrow {
    top: 40%;
  }
  .carousel2 {
    border: 0;
  }
  .carousel3 {
    .carousel3-item {
      height: 100%;
      border: 0;
    }
  }
  .right-banner-before1 {
    display: none;
  }
  .right-banner-before2 {
    display: none;
  }
}
</style>
<style>
/* web端样式 */
.el-carousel__container {
  height: 100% !important;
}
.el-carousel__indicator--horizontal {
  padding: 12px 10px;
}
.el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  border: 2px solid #fff;
  opacity: 0.2;
}
.el-carousel__indicator.is-active button {
  background: #9c9c9c;
  border: 2px solid #fff;
}
.el-carousel__indicators {
  bottom: 3%;
}

/* 移动端样式 */
@media screen and (max-width: 650px) {
  .el-carousel {
    height: 200px !important;
  }
  .el-carousel--card {
    height: 110px !important;
  }
  /* .el-carousel__item--card {
        width: 70%;
    } */
}
</style>